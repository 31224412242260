import React, { useRef, forwardRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDiscord,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// ASSETS

const Team = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      dividerWhite: file(
        relativePath: { eq: "backgrounds/divider-white.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      curveBGTop: file(relativePath: { eq: "backgrounds/curve-bg-top.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      curveBGBottom: file(
        relativePath: { eq: "backgrounds/curve-bg-bottom.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector(".team-heading"),
      {
        y: -50,
        scale: 0,
        autoAlpha: 0,
      },
      {
        y: 0,
        scale: 1,
        autoAlpha: 1,
        ease: "elastic",
        lazy: false,
        scrollTrigger: {
          trigger: element.querySelector(".team-heading"),
          start: "top center+=200px",
          ease: "elastic",
          toggleActions: "play pause resume reverse",
          markers: false,
          id: "team-heading",
        },
      }
    )
  })

  const photoRefs = useRef([])
  photoRefs.current = []

  const addToPhotoRefs = el => {
    if (el && !photoRefs.current.includes(el)) {
      photoRefs.current.push(el)
    }
  }

  useEffect(() => {
    photoRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          scale: 0,
        },
        {
          autoAlpha: 1,
          scale: 1,
          ease: "elastic",
          lazy: false,
          scrollTrigger: {
            trigger: el,
            start: "top bottom-=100",
            toggleActions: "play none none reverse",
          },
        }
      )
    })
  })

  const textRefs = useRef([])
  textRefs.current = []

  const addToRefs = el => {
    if (el && !textRefs.current.includes(el)) {
      textRefs.current.push(el)
    }
  }

  useEffect(() => {
    textRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          y: -100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: "power4",
          stagger: 0.5,
          lazy: false,
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none reverse",
          },
        }
      )
    })
  })

  const curvedTopRef = useRef()
  const curvedBottomRef = useRef()

  useEffect(() => {
    const element = ref.current
    const top = curvedTopRef.current
    const bottom = curvedBottomRef.current

    ScrollTrigger.matchMedia({
      "(max-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top top",
            scrub: 1,
            markers: false,
            id: "team",
          },
        })
        tl.to(
          top,
          6,
          {
            y: -100,
          },
          "-=6"
        ).to(
          bottom,
          6,
          {
            y: -50,
          },
          "-=6"
        )
      },
      "(min-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top top",
            scrub: 1,
            markers: false,
            id: "team",
          },
        })
        tl.to(
          top,
          6,
          {
            y: -200,
          },
          "-=6"
        ).to(
          bottom,
          6,
          {
            y: -150,
          },
          "-=6"
        )
      },
    })
  }, [ref, curvedTopRef, curvedBottomRef])

  return (
    <section
      ref={ref}
      id="team"
      className="overflow-x-hiddens relative w-full overflow-y-hidden bg-[#00AAFF] lg:-mt-64"
    >
      <StaticImage
        src="../assets/backgrounds/stars-wide.png"
        alt="texture"
        className="!absolute top-16 left-0 w-full !mix-blend-soft-light lg:top-0"
        objectFit="contain"
        placeholder="none"
      />
      <BackgroundImage
        tag="div"
        fluid={data.dividerWhite.childImageSharp.fluid}
        className="flex flex-col items-center justify-center py-8 lg:py-16"
        fadeIn={false}
      >
        <StaticImage
          src="../assets/team/team-heading.png"
          alt="Team"
          className="team-heading mx-auto max-h-[70px] -rotate-3 transform lg:max-h-[120px]"
          objectFit="contain"
          height={120}
          placeholder="none"
          layout="constrained"
        />
      </BackgroundImage>
      <StaticImage
        src="../assets/backgrounds/duotone-texture.png"
        alt="texture"
        className="!absolute top-0 bottom-0 left-0 right-0 z-10 opacity-20 !mix-blend-soft-light"
        objectFit="cover"
      />
      <div className="relative z-20 mx-auto mb-12 max-w-5xl px-4 lg:mb-32">
        <div className="team-1 text-white">
          <div className="float-left" ref={addToPhotoRefs}>
            <StaticImage
              src="../assets/R6.png"
              alt="Team"
              className="mr-6  mb-2 !hidden w-full max-w-[224px] -rotate-3 transform lg:!block"
              objectFit="contain"
              placeholder="none"
            />
          </div>
          <div className="hidden lg:block">
            <p className="custom-font mb-1 text-2xl text-[#FFDD00]">
              Pat - “หมึก” in Thai
            </p>
            <p className="mb-4 text-lg font-light uppercase tracking-widest">
              Jellow Artist
            </p>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start gap-4 lg:!hidden">
            <StaticImage
              src="../assets/R6.png"
              alt="Team"
              className="w-full max-w-[124px] -rotate-3 transform"
              objectFit="contain"
              placeholder="none"
            />
            <div>
              <p className="custom-font mb-1 text-2xl text-[#FFDD00]">
                Pat - “หมึก” in Thai
              </p>
              <p className="text-lg font-light uppercase tracking-widest">
                Jellow Artist
              </p>
            </div>
          </div>
          <div
            className="text-xs leading-loose lg:text-sm lg:leading-9"
            ref={addToRefs}
          >
            <p className="mb-8 font-poppins font-bold">
              After graduating as an Industrial Designer in 2007, Pat co-founded
              a design company in Thailand to help SMBs with graphic design, art
              direction, and photography. From 2015, Pat pursued his career as a
              freelance Graphic Designer and Photographer and to this date, his
              passion never stopped growing and has been telling amazing stories
              through his art.
            </p>
            <p className="mb-8 font-poppins font-bold">
              In 2015, Pat also combined his love for design and octopus and
              created a Facebook comic page called the “Yellow Octopus” just for
              fun. He later turned the character into a “Jellow Octopus” that
              you see today.
            </p>
            <p className="mb-8 font-poppins font-bold">
              Pat believed that life is too boring especially when you have a
              9-5 job where each day repeats itself so he decided to create art
              that will spread joy to others. He wanted the art to be cute and
              characters to do silly things that normally won’t happen in real
              life so that through his art, people can explore their imagination
              and take a break from reality. Since then, Pat has created the
              Jellow Universe, where he continues to spread happiness and love
              through this big yellow ball of sunshine - The Jellow Octopus.
            </p>
            <div className="flex flex-row items-center">
              <p className="mr-4 font-bold">Social Links</p>
              <a
                className="mr-4"
                href="https://www.linkedin.com/in/phatthara-lertsukittipongsa-3ab0ba124/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-xl hover:text-[#FFDD00]"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-20">
        <div className="absolute top-0 left-0 h-full w-full" ref={curvedTopRef}>
          <StaticImage
            src="../assets/backgrounds/curve-bg-top.png"
            alt="Curved BG Top"
            layout="fullWidth"
            objectFit="cover"
            className="h-[125%] w-full"
            objectPosition={"top"}
            placeholder="none"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div
          className="relative grid items-center gap-y-6"
          style={{ gridArea: "1/1" }}
        >
          <div className="team-2 mx-auto grid max-w-5xl grid-cols-8 items-center gap-8 px-4 lg:py-32">
            <div className="col-span-8 w-full text-white lg:col-span-6">
              <div className="mb-4 flex flex-row items-center justify-start gap-4 lg:justify-start">
                <StaticImage
                  src="../assets/R1.png"
                  alt="Team"
                  className="w-full max-w-[124px] -rotate-3 transform lg:!hidden"
                  objectFit="contain"
                  placeholder="none"
                />
                <div>
                  <p className="custom-font mb-1 text-2xl text-[#FFDD00]">
                    Fred
                  </p>
                  <p className="text-lg font-light uppercase tracking-widest lg:mb-4">
                    Jellow Director of Marketing
                  </p>
                  <p className="hidden text-xs font-bold uppercase tracking-widest lg:mb-4 lg:block">
                    Owner of MAYC #8519, Azuki #4254, Doodle #2884, Coolsman,
                    Karafuru & More
                  </p>
                </div>
              </div>
              <div
                className="text-xs leading-loose lg:text-sm lg:leading-9"
                ref={addToRefs}
              >
                <p className="mb-4 font-poppins font-bold lg:mb-8">
                  As an avid investor and expert marketing consultant in the NFT
                  space, Fred is the brain behind all creative strategies for
                  Jellow Octopus. Through his leadership and over 15 years of
                  experience in media buying and entrepreneurship, Fred is
                  dedicated to building a world-class Jellowo community with
                  unparalleled privileges.
                </p>
                <p className="mb-4 text-xs font-bold uppercase tracking-widest lg:hidden">
                  Owner of MAYC #8519, Azuki #4254, Doodle #2884, Coolsman,
                  Karafuru & More
                </p>
                <div className="flex flex-row items-center">
                  <p className="mr-4 font-bold">Social Links</p>
                  <a
                    className="mr-4"
                    href="https://twitter.com/lilbuddha_eth"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-xl hover:text-[#FFDD00]"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-2 !hidden lg:!block" ref={addToPhotoRefs}>
              <StaticImage
                src="../assets/R1.png"
                alt="Team"
                className="w-full rotate-3 transform"
                objectFit="contain"
                placeholder="none"
              />
            </div>
          </div>
          <div className="relative">
            <div
              className="absolute top-0 left-0 h-full w-full"
              ref={curvedBottomRef}
            >
              <StaticImage
                src="../assets/backgrounds/curve-bg-bottom.png"
                alt="Curved BG bottom"
                layout="fullWidth"
                objectFit="cover"
                className="h-[125%] w-full"
                objectPosition={"top"}
                placeholder="none"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div className="team-3 mx-auto grid max-w-5xl grid-cols-8 items-center gap-8 px-4 lg:py-32">
              <div
                className="col-span-2 !hidden lg:!block"
                ref={addToPhotoRefs}
              >
                <StaticImage
                  src="../assets/R9.png"
                  alt="Team"
                  className="w-full -rotate-3 transform"
                  objectFit="cover"
                  placeholder="none"
                />
              </div>
              <div className="z-20 col-span-8 mb-6 w-full text-white lg:col-span-6">
                <div className="mb-4 flex flex-row items-center justify-start gap-4 lg:justify-start">
                  <StaticImage
                    src="../assets/R9.png"
                    alt="Team"
                    className="w-full max-w-[124px] -rotate-3 transform lg:!hidden"
                    objectFit="contain"
                    placeholder="none"
                  />
                  <div>
                    <p className="custom-font mb-1 text-2xl text-[#FFDD00]">
                      Jian
                    </p>
                    <p className="text-lg font-light uppercase tracking-widest lg:mb-4">
                      Jellow Director of Development
                    </p>
                    <p className="hidden text-xs font-bold uppercase tracking-widest lg:mb-4 lg:block">
                      Owner of MAYC #3033, MetaHero #1506, DeadFellaz $4549 &
                      More
                    </p>
                  </div>
                </div>
                <div
                  className="text-xs leading-loose lg:text-sm lg:leading-9"
                  ref={addToRefs}
                >
                  <p className="mb-4 font-poppins font-bold lg:mb-8">
                    With over 16 years of experience in business development and
                    8 years in the NFT/crypto industry, Jian has a wealth of
                    experience and connections that created some of the biggest
                    launches in recent years. His passion and drive to make
                    everyone succeed is the motivating force for each project he
                    does.
                  </p>
                  <p className="mb-4 text-xs font-bold uppercase tracking-widest lg:hidden">
                    Owner of MAYC #3033, MetaHero #1506, DeadFellaz $4549 & More
                  </p>
                  <div className="flex flex-row items-center">
                    <p className="mr-4 font-bold">Social Links</p>
                    <a
                      className="mr-4"
                      href="https://instagram.com/jiantam"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="text-xl hover:text-[#FFDD00]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Team

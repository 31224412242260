import React, { useLayoutEffect, forwardRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
// ASSETS
import jellowStar from "../assets/about/about-jelllow-star-min.png"
import jellowBG from "../assets/about/about-jellow-bg-min.png"

const About = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      yellowBG: file(relativePath: { eq: "backgrounds/yellow-bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useLayoutEffect(() => {
    const element = ref.current

    ScrollTrigger.matchMedia({
      "(max-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "-=200%",
            pin: false,
            pinSpacing: false,
            scrub: 1,
            markers: false,
            id: "about",
          },
        })
        tl.from(
          ".about-stars",
          6,
          {
            y: -100,
            autoAlpha: 0,
          },
          "-=6"
        )
          .from(
            ".about-heading",
            6,
            {
              y: 100,
              autoAlpha: 0,
            },
            "-=6"
          )
          .from(
            ".about-text-1",
            6,
            {
              y: 200,
              autoAlpha: 0,
            },
            "-=6"
          )
          .from(
            ".about-text-2, .big-jellow-mobile",
            6,
            {
              y: 200,
              autoAlpha: 0,
            },
            "-=6"
          )
          .from(
            ".big-jellow",
            6,
            {
              x: 1600,
              autoAlpha: 0,
            },
            "-=6"
          )
        gsap.from(".about-bg", {
          autoAlpha: 0,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            pin: false,
            pinSpacing: true,
            markers: false,
            id: "about-section",
            toggleActions: "play none none reverse",
          },
        })
      },
      "(min-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top top",
            end: "-=200%",
            pin: true,
            pinSpacing: true,
            scrub: 1,
            markers: false,
            id: "about-content",
          },
        })
        tl.fromTo(
          ".about-stars",
          6,
          {
            y: -700,
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
          },
          "-=6"
        )
          .fromTo(
            ".about-heading",
            6,
            {
              y: -800,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
          .fromTo(
            ".about-text-1",
            6,
            {
              y: -600,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
          .fromTo(
            ".about-text-2",
            6,
            {
              y: -400,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
          .fromTo(
            ".big-jellow",
            6,
            {
              x: 1600,
              autoAlpha: 0,
            },
            {
              x: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
          .fromTo(
            "#about-jellow-bg",
            6,
            {
              y: 200,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
          .fromTo(
            "#about-jellow-star",
            6,
            {
              y: 600,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
            },
            "-=6"
          )
        gsap.fromTo(
          ".about-bg",
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            scrollTrigger: {
              trigger: element,
              start: "top top",
              pin: true,
              pinSpacing: true,
              markers: false,
              id: "about",
              toggleActions: "play none none reverse",
            },
          }
        )
      },
    })
  }, [ref])

  return (
    <section ref={ref} className="h-full min-h-screen">
      <BackgroundImage
        className="about-bg section h-full md:flex lg:pt-16"
        tag="section"
        fluid={data.yellowBG.childImageSharp.fluid}
        fadeIn={false}
        id="about"
      >
        <div className="align-center relative mx-auto grid max-w-6xl grid-cols-10 items-center gap-6 overflow-x-hidden px-4 pt-8 md:overflow-x-visible">
          <StaticImage
            src="../assets/backgrounds/stars-narrow.png"
            alt="Stars"
            className="about-stars !absolute top-0 w-full mix-blend-soft-light lg:-left-44"
            objectFit="contain"
            placeholder="none"
          />
          <div className="col-span-12 col-start-1 lg:col-span-7">
            <h1 className="about-heading custom-font stroke mb-6 text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:mb-12 lg:-mr-12 lg:text-4xl lg:leading-[4.5rem]">
              Welcome To The Jellowo World Where Happiness Jiggles All Around
            </h1>
            <p className="about-text-1 mb-6 font-poppins text-xs font-bold leading-loose lg:mb-12 lg:text-base lg:leading-9">
              When the real world is chaotic and life happens, we all need to
              rest by taking some time to let our minds breathe and recuperate
              before we start the grind all over again. Sometimes we just need
              to hear the right words or look at cute funny things to experience
              simple moments of happiness in the midst of all the darkness. In
              the Jellowo World, you will experience just that by letting our
              family of Jellow Octopus take you on a getaway journey so that you
              can find happiness from within, feel refreshed, and have the
              strength to carry on no matter the circumstances.
            </p>
            <StaticImage
              src="../assets/about/about-graphic.png"
              alt="Big Jellow"
              className="big-jellow-mobile float-right -mr-24 ml-1 w-1/2 lg:!hidden"
              objectFit="contain"
              placeholder="none"
            />
            <p className="about-text-2 font-poppins text-xs font-bold leading-loose lg:text-base lg:leading-9">
              Jellowo is a collection of 8,888 two-legged, jello-like octopus
              NFTs living on the Ethereum blockchain. Your Jellowo will grant
              you pioneer access to our play-to-earn scavenger puzzle game,
              daily distribution of our $JELLO tokens that are used towards our
              upcoming flagship games, and future perks and benefits that can be
              unlocked by the community through roadmap activation
            </p>
          </div>
          <div className="col-span-3 col-start-8 hidden lg:block">
            <div className="relative min-w-[800px]">
              <img id="about-jellow-bg" src={jellowBG} alt="Jellow BG" />
              <img
                id="about-jellow-star"
                className="-left-42 absolute top-0 bottom-0 mt-auto mb-auto max-w-[50%]"
                src={jellowStar}
                alt="Jellow BG"
              />
            </div>
          </div>
          <div className="col-span-12 text-center">
            <a
              href="#specs"
              className="inline-block rounded-full bg-sky-400 px-6 py-2 font-poppins font-bold text-white"
            >
              <p className="text-sm lg:text-base">
                <FontAwesomeIcon icon={faAngleDown} /> Explore Jellows
                <FontAwesomeIcon icon={faAngleDown} />
              </p>
            </a>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
})

export default About

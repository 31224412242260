import React, { useRef, forwardRef, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
// ASSETS

const Roadmap = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      footsteps: file(relativePath: { eq: "backgrounds/footsteps.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector(".roadmap-heading"),
      {
        y: -50,
        scale: 0,
        autoAlpha: 0,
      },
      {
        y: 0,
        scale: 1,
        autoAlpha: 1,
        ease: "elastic",
        lazy: false,
        scrollTrigger: {
          trigger: element.querySelector(".roadmap-heading"),
          start: "top center+=200px",
          ease: "elastic",
          toggleActions: "play pause resume reverse",
          markers: false,
          id: "roadmap-heading",
        },
      }
    )
  })

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector(".roadmap-text"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        lazy: false,
        scrollTrigger: {
          trigger: element.querySelector(".roadmap-text"),
          start: "top center",
          ease: "elastic",
          toggleActions: "play pause resume reverse",
          id: "roadmap-text",
        },
      }
    )
  })

  const roadmapRef = useRef([])
  roadmapRef.current = []

  const addToRoadmapRef = el => {
    if (el && !roadmapRef.current.includes(el)) {
      roadmapRef.current.push(el)
    }
  }

  useEffect(() => {
    roadmapRef.current.forEach((el, index) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=100",
          toggleActions: "play pause resume reverse",
          markers: false,
          id: "percent",
        },
      })
      tl.fromTo(
        el.querySelector(".roadmap-percent"),
        1,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          autoAlpha: 1,
          x: 0,
          ease: "elastic",
          durations: 0.1,
          lazy: false,
        },
        "-=1"
      )
      tl.fromTo(
        el.querySelector(".roadmap-card"),
        1,
        {
          x: 50,
          autoAlpha: 0,
          rotate: 0,
        },
        {
          x: 0,
          autoAlpha: 1,
          rotate: `${index % 2 === 0 ? "-3" : "3"}`,
          ease: "elastic",
          durations: 0.1,
          lazy: false,
        },
        "-=1"
      )
    })
  })

  return (
    <section
      id="roadmap"
      ref={ref}
      className="w-full overflow-x-hidden bg-gradient-to-b from-[#F0F2F5] to-white pt-12 lg:-mt-32 lg:pt-32 lg:pb-64"
    >
      <BackgroundImage
        tag="section"
        fluid={data.footsteps.childImageSharp.fluid}
        className="flex flex-col items-center justify-center bg-contain bg-repeat-x py-8 lg:py-16"
        fadeIn={false}
        style={{ backgroundSize: "contain" }}
      >
        <StaticImage
          src="../assets/roadmap/roadmap-heading.png"
          alt="Roadmap"
          className="roadmap-heading mx-auto max-h-[50px] -rotate-3 transform lg:max-h-[80px]"
          objectFit="contain"
          height={80}
          placeholder="none"
          layout="constrained"
        />
      </BackgroundImage>
      <div className="mb-12 w-full px-4 lg:mb-24">
        <div className="roadmap-text relative mx-auto max-w-5xl text-center font-poppins text-xs font-bold leading-loose lg:text-base lg:leading-9">
          <p className="mb-6">
            Happiness is when we all come together - this is the Jellowo Family
            mantra. We’re dedicated to bringing the greatest joy to the
            community for the long-run but we cannot do it without your support!
          </p>
          <p>
            So here are some goals that we can achieve together. Once we hit a
            sales target by percentage, we will begin to work on the respective
            goal!
          </p>
        </div>
      </div>
      <div className="relative mx-auto grid max-w-6xl grid-cols-12 px-4">
        <div className="!absolute left-0 top-0 z-20 col-span-2 col-start-1 h-[0%] lg:col-span-1 lg:col-end-2">
          <div className="relative h-full">
            <div className="sticky top-1/2 flex items-center justify-center">
              <StaticImage
                src="../assets/jellow-scroll.png"
                alt="jellow scroll"
                className="w-[110%]"
                objectFit="contain"
                placeholder="none"
              />
            </div>
          </div>
        </div>

        <div className="z-10 col-span-12 col-start-1 flex flex-col">
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:rounded-full after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#E63439] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#E63439] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  10%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  Reinvest In The Development Of Our First Play-To-Earn “Where’s
                  Jellowo?” Game.{" "}
                  <Link className="text-blue-500 underline" to="/wheres-jellow">
                    Learn More
                  </Link>
                </p>
              </div>
              <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                <p className="custom-font text-lg text-[#E63439] lg:text-2xl">
                  Where’s Jellowo?
                </p>
                <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                  To keep or not to keep - fate is in your hands in our
                  first-ever play-to-earn scavenger puzzle game, “Where’s
                  Jellowo?”. All holders will be granted exclusive access to the
                  game for a chance to win weekly rewards or perhaps an
                  attractive income.
                </p>
              </div>
            </div>
          </div>
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#FF9D5D] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#FF9D5D] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  20%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  Introducing The $JELLO Token & Whitelist Allocations Available
                  VIA Our Loot Boxes
                </p>
              </div>
              <div className="col-span-6 col-start-7 place-self-center">
                <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:-rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                  <p className="custom-font text-lg text-[#FF9D5D] lg:text-2xl">
                    $JELLO Token Distribution
                  </p>
                  <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                    As a holder of our Jellowo NFTs, you will be eligible to
                    receive our loot box(es) every week for $JELLO tokens that
                    will be used in our play-to-earn rewards game to unlock even
                    bigger prizes.You’ll even earn yourself whitelist
                    allocations for future NFT projects as well.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#FED141] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#FED141] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  40%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  “Where’s Jellowo?” Game Release For Monthly Prizes
                </p>
              </div>
              <div className="col-span-6 col-start-7 place-self-center">
                <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                  <p className="custom-font text-lg text-[#FED141] lg:text-2xl">
                    Where’s Jellowo? Game Prize!
                  </p>
                  <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                    The lucky holder with the winning Jellowo puzzle piece will
                    win the game prize of 4 ETH! But that’s not all - the rest
                    of you can still participate in a raffle draw for $JELLO
                    token rewards!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#50DED1] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#50DED1] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  60%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  Exclusive Member’s Access To Jellowo Merch Store For Limited
                  Edition T-shirts, Hoodies, Huggable Jellowo And Other Goodies
                </p>
              </div>
              <div className="col-span-6 col-start-7 place-self-center">
                <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:-rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                  <p className="custom-font text-lg text-[#50DED1] lg:text-2xl">
                    Jellowo Merchandise Store
                  </p>
                  <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                    How about hugging a Jellowo when you’re down? All holders
                    get exclusive access to limited edition goods from our
                    Jellowo merch stores including tees, hoodies, mugs, and of
                    course, a stuffed Jellowo!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#7FCCFF] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#7FCCFF] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  80%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  Development Of Our Second Jellowo Collection Where Members Get
                  Exclusive Access + Perks
                </p>
              </div>
              <div className="col-span-6 col-start-7 place-self-center">
                <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                  <p className="custom-font text-lg text-[#7FCCFF] lg:text-2xl">
                    New Jellowo Collection
                  </p>
                  <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                    It’s time to expand the Jellowo family! A new collection of
                    Jellowos will be born - they will be more mysterious, more
                    competitive, and more aggressive than ever before to fight
                    back all the negativities that you don’t need!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-full flex-1 grid-cols-12 gap-3">
            <div className="after:-z-1 after:-translate-1/2 relative col-span-2 col-start-1 flex justify-center after:!absolute after:top-0 after:bottom-0 after:left-1/2 after:border-l-4 after:border-black after:content-[''] lg:col-span-1 ">
              <div className="z-20 h-4 w-4 place-self-center rounded-full bg-[#99B1FB] ring-[6px] ring-black drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:h-6 lg:w-6"></div>
            </div>
            <div
              className="custom-font col-span-10 col-start-3 my-6 flex w-full flex-col items-center justify-between gap-y-3 lg:col-span-11 lg:col-start-2 lg:h-[400px] lg:flex-row"
              ref={addToRoadmapRef}
            >
              <div className="roadmap-percent max-w-[345px]">
                <div className="mb-4 inline-block rounded-lg border-4 border-black bg-[#99B1FB] px-3 py-1 text-2xl text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)]">
                  100%
                </div>
                <p className="mb-2 text-sm leading-loose text-black lg:leading-9">
                  Development Of Our Flagship Play-To-Earn “Go Go Jellow” Racing
                  Mobile Game
                </p>
              </div>
              <div className="col-span-6 col-start-7 place-self-center">
                <div className="roadmap-card lg:border-6 flex w-full max-w-[600px] transform flex-col justify-center gap-y-2 rounded-2xl border-4 border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:col-span-6 lg:col-start-7 lg:-rotate-3 lg:items-center lg:gap-y-4 lg:text-center">
                  <p className="custom-font text-lg text-[#99B1FB] lg:text-2xl">
                    Go Go Jellow
                  </p>
                  <p className="text-xs leading-loose lg:text-sm lg:leading-8">
                    With the new Jellowos, you’ll be ready to participate in our
                    first-ever mobile racing game, “Go Go Jellow” to compete
                    against each other for the weekly podium and win amazing
                    prizes!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Roadmap

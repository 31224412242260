import React, { forwardRef, useLayoutEffect } from "react"
import useSound from "use-sound"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// ASSETS
import pop2SFX from "../assets/sounds/pop2.wav"
import logo from "../assets/hero/logo-min.png"
import bigJellow from "../assets/hero/jellow-big-min.png"
import leftJellows from "../assets/hero/jellow-left-min.png"
import rightJellows from "../assets/hero/jellow-right-min.png"
import ctaBtn from "../assets/hero/discord-cta-min.png"
import heroBG from "../assets/hero/bg-min.png"
import bottomHero from "../assets/hero/footer-min.png"
import topJellow from "../assets/hero/jellow-top-min.png"
import frontJellow from "../assets/hero/jellow-front-min.png"
import lootboxFront from "../assets/hero/lootboxes-min.png"
import lootboxBack from "../assets/hero/lootbox1-min.png"

const Hero = forwardRef((props, ref) => {
  const [play, { stop }] = useSound(pop2SFX)

  useLayoutEffect(() => {
    const element = ref.current
    ScrollTrigger.matchMedia({
      "(max-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top top+=64px",
            pin: true,
            pinSpacing: false,
            scrub: 0,
            markers: false,
            id: "hero",
          },
        })
        tl.to(
          "#hero-right-jellow",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero-left-jellow",
          6,
          {
            y: -300,
          },
          "-=6"
        )
        tl.to(
          "#hero-loot-front",
          6,
          {
            y: -500,
          },
          "-=6"
        )
        tl.to(
          "#hero-loot-back",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero-big-jellow",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero-bg",
          6,
          {
            y: -50,
          },
          "-=6"
        )
        tl.to(
          "#hero-btn",
          6,
          {
            y: -500,
          },
          "-=6"
        )
        tl.to(
          "#hero-logo",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero",
          6,
          {
            y: -800,
          },
          "-=6"
        )
      },
      "(min-width: 1024px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: "top top+=64px",
            pin: true,
            pinSpacing: false,
            scrub: 1,
            markers: false,
            id: "hero",
          },
        })
        tl.to(
          "#hero-right-jellow",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero-left-jellow",
          6,
          {
            y: -200,
          },
          "-=6"
        )
        tl.to(
          "#hero-top-jellow",
          6,
          {
            y: -100,
          },
          "-=6"
        )
        tl.to(
          "#hero-front-jellow",
          6,
          {
            y: -500,
          },
          "-=6"
        )
        tl.to(
          "#hero-loot-front",
          6,
          {
            y: -1200,
          },
          "-=6"
        )
        tl.to(
          "#hero-loot-back",
          6,
          {
            y: -500,
          },
          "-=6"
        )
        tl.to(
          "#hero-big-jellow",
          6,
          {
            y: -900,
          },
          "-=6"
        )
        tl.to(
          "#hero-bg",
          6,
          {
            y: -50,
          },
          "-=6"
        )
        tl.to(
          "#hero-btn",
          6,
          {
            opacity: 0,
          },
          "-=50%"
        )
        tl.to(
          "#hero-logo",
          6,
          {
            scale: 90,
            x: "-16000px",
          },
          ">-=50%"
        )
        tl.to(
          element,
          {
            autoAlpha: 0,
          },
          ">"
        )
      },
    })
  }, [ref])

  return (
    <section
      ref={ref}
      id="hero"
      className="w-full overflow-x-hidden bg-[#FFDD00]"
    >
      <div className="relative z-20 overflow-hidden opacity-100">
        <div className="relative h-[95vh]">
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full">
            <img
              id="hero-bg"
              src={heroBG}
              alt="Jellow Background"
              className="!absolute right-0 top-0 h-full w-full scale-110 object-cover"
            />
          </div>
          <div className="!absolute top-0 left-[50%] ml-auto mr-auto w-[100px] lg:-top-8 lg:left-0 lg:-right-12 lg:h-[220px] lg:w-[220px]">
            <img
              id="hero-top-jellow"
              src={topJellow}
              alt="Jellow Top"
              className="!absolute right-0 top-0 h-full w-full object-cover"
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-right-jellow"
              src={rightJellows}
              alt="Jellow Right"
              className="2xl:max-w-[760px]s !absolute -right-[50%] top-8 max-w-[300px] object-contain object-right-top md:-right-12 md:-top-20 md:max-w-[400px] 2xl:-right-12 2xl:-top-20 "
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-big-jellow"
              src={bigJellow}
              alt="Big Jellow"
              className="!absolute bottom-0 -right-16 max-w-[200px] object-contain object-right-bottom md:bottom-6 md:right-0 md:max-w-[400px] 2xl:bottom-14 2xl:right-20 2xl:max-w-[543px]"
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-left-jellow"
              src={leftJellows}
              alt="Jellow Left"
              className="!absolute -left-[50%] top-8 max-w-[300px] object-contain object-left-top md:-left-24 md:-top-20 md:max-w-[400px] 2xl:-left-6 2xl:-top-[200px] 2xl:max-w-[716px]"
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-loot-back"
              src={lootboxBack}
              alt="Lootbox Back"
              className="!absolute top-44 -left-28 max-w-[100px] object-contain object-left-top 2xl:max-w-[420px] "
            />
          </div>
          <div className="!absolute top-0 left-0 h-full w-full">
            <img
              id="hero-footer"
              src={bottomHero}
              alt="Bottom"
              className="!absolute bottom-0 left-0"
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-loot-front"
              src={lootboxFront}
              alt="Lootbox Front"
              className="!absolute bottom-0 -left-16 max-w-[200px] object-contain object-left-bottom md:bottom-2 md:left-0 md:max-w-[400px] 2xl:bottom-2 2xl:left-20 2xl:max-w-[667px]"
            />
          </div>
          <div className="!absolute top-0 left-0 right-0 ml-auto mr-auto h-full w-full max-w-[1920px]">
            <img
              id="hero-front-jellow"
              src={frontJellow}
              alt="Jellow Front"
              className="!absolute left-0 top-[50%] max-w-[100px] object-contain object-left-bottom 2xl:left-[491px] 2xl:bottom-[290px] 2xl:max-w-[382px]"
            />
          </div>
          <div className="!absolute top-8 left-0 right-0 ml-auto mr-auto flex h-full w-full max-w-[1920px] flex-col items-center gap-14 px-4 md:top-16">
            <img
              id="hero-logo"
              src={logo}
              alt="Logo"
              className="w-full max-w-[767px] md:w-1/2"
            />
            <a
              id="hero-btn"
              href="http://discord.gg/jellowo"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
            >
              <img
                src={ctaBtn}
                alt="CTA Button"
                className="mx-auto block w-full max-w-[250px] transition-all duration-75 ease-in-out hover:scale-110 hover:drop-shadow-2xl md:max-w-[50%] xl:max-w-[324px]"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Hero

import React, { useState, useRef } from "react"
import { Helmet } from "react-helmet"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useSound from "use-sound"
import { graphql } from "gatsby"
import pop2SFX from "../assets/sounds/pop2.wav"
import tickSfx from "../assets/sounds/tick.mp3"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// Components
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import About from "../components/About"
import Specs from "../components/Specs"
import Roadmap from "../components/Roadmap"
import Team from "../components/Team"

export default function Home({ data, location }) {
  gsap.registerPlugin(ScrollTrigger)
  const [play, { stop }] = useSound(pop2SFX)
  const [playTick] = useSound(tickSfx)
  const [volume, setVolume] = useState(false)

  const ref = useRef()
  const heroRef = useRef()
  const aboutRef = useRef()
  const specsRef = useRef()
  const roadmapRef = useRef()
  const teamRef = useRef()

  const toggleMute = () => {
    volume ? stop() : playTick()
    setVolume(!volume)
  }

  return (
    <div ref={ref} className="relative w-full bg-[#FFDD00]"> 
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title} | Home</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <NavBar sticky={true} volume={volume} path={location.pathname} />
      <Hero ref={heroRef} volume={volume} />
      <About ref={aboutRef} />
      <Specs ref={specsRef} />
      <Roadmap ref={roadmapRef} />
      <Team ref={teamRef} />
      <Footer />
      <div className="sticky bottom-6 left-6 z-50 inline-block">
        <button
          className="transform rounded-md bg-white/75 p-2 text-gray-600 shadow ring-orange-400 backdrop-blur-sm transition-all hover:-translate-y-1 hover:shadow-lg hover:ring-2"
          onClick={() => {
            toggleMute()
          }}
        >
          {volume !== false ? (
            <FontAwesomeIcon icon={faVolumeHigh} className="text-2xl" />
          ) : (
            <FontAwesomeIcon icon={faVolumeMute} className="text-2xl" />
          )}
        </button>
      </div>
    </div>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

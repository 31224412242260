import React, { forwardRef, useRef, useEffect, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { gsap } from "gsap"
// ASSETS

const Specs = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      dividerGrey: file(relativePath: { eq: "backgrounds/divider-grey.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { eq: "jellows" } }) {
        nodes {
          base
          id
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)

  var slideLeftSetting = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    initialSlide: 1,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    variableWidth: true,
    swipeToSlide: true,
    adaptiveHeight: false,
    arrows: false,
    slidesPerRow: 1,
    draggable: true,
  }
  var slideRightSetting = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    initialSlide: 1,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    variableWidth: true,
    swipeToSlide: true,
    adaptiveHeight: false,
    arrows: false,
    slidesPerRow: 1,
    draggable: true,
    rtl: true,
  }

  const photoRef = useRef([])
  photoRef.current = []

  const addToRefs = el => {
    if (el && !photoRef.current.includes(el)) {
      photoRef.current.push(el)
    }
  }

  useLayoutEffect(() => {
    const element = ref.current
    gsap.fromTo(
      photoRef.current,
      {
        autoAlpha: 0,
        scale: 0,
      },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        ease: "elastic",
        lazy: false,
        stagger: {
          each: 0.006,
          from: "random",
        },
        scrollTrigger: {
          trigger: element,
          start: "top center",
          id: "specs",
          toggleActions: "play reset play reset",
        },
      }
    )
  }, [ref])

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector(".specs-heading"),
      {
        y: -50,
        scale: 0,
        autoAlpha: 0,
      },
      {
        y: 0,
        scale: 1,
        autoAlpha: 1,
        ease: "elastic",
        lazy: false,
        scrollTrigger: {
          trigger: element.querySelector(".specs-heading"),
          start: "top bottom",
          ease: "elastic",
          toggleActions: "play pause resume reverse",
          markers: false,
          id: "specs-heading",
        },
      }
    )
  })

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector(".specs-text"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        lazy: false,
        scrollTrigger: {
          trigger: element.querySelector(".specs-text"),
          start: "top bottom",
          ease: "elastic",
          toggleActions: "play pause resume reverse",
          markers: false,
          id: "specs-text",
        },
      }
    )
  })

  return (
    <section ref={ref} id="specs" className="w-full overflow-x-hidden">
      <div className="relative flex flex-col gap-y-4 overflow-x-hidden bg-[#FFDD00] pb-32 pt-4 lg:pb-64 lg:pt-32">
        <div></div>
        <div className="z-40">
          <Slider {...slideLeftSetting}>
            {data.images.nodes.slice(0, 14).map((jellow, i) => {
              return (
                <div key={i} className="p-2 lg:p-6" ref={addToRefs}>
                  <GatsbyImage
                    className="specs-preview w-[100px] lg:w-[200px]"
                    image={getImage(jellow)}
                    alt={`${jellow}${i}`}
                    placeholder="none"
                  />
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="z-40">
          <Slider {...slideRightSetting}>
            {data.images.nodes.slice(14, 28).map((jellow, i) => {
              return (
                <div key={i} className="p-2 lg:p-6" ref={addToRefs}>
                  <GatsbyImage
                    className="specs-preview w-[100px] lg:w-[200px]"
                    image={getImage(jellow)}
                    alt={`${jellow}${i}`}
                    placeholder="none"
                  />
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="z-40">
          <Slider {...slideLeftSetting}>
            {data.images.nodes.slice(28, 42).map((jellow, i) => {
              return (
                <div key={i} className="p-2 lg:p-6" ref={addToRefs}>
                  <GatsbyImage
                    className="specs-preview w-[100px] lg:w-[200px]"
                    image={getImage(jellow)}
                    alt={`${jellow}${i}`}
                    placeholder="none"
                  />
                </div>
              )
            })}
          </Slider>
        </div>
        <StaticImage
          src="../assets/backgrounds/stars-wide.png"
          alt="Stars"
          className="!absolute bottom-0 w-full scale-y-[-1] mix-blend-soft-light"
          objectFit="contain"
          placeholder="none"
        />
      </div>
      <div className="-mt-32 lg:-mt-64 lg:mb-6">
        <BackgroundImage
          tag="section"
          fluid={data.dividerGrey.childImageSharp.fluid}
          className="flex flex-col items-center justify-center bg-bottom py-8 lg:py-16"
          fadeIn={false}
        >
          <div className="specs-heading">
            <StaticImage
              src="../assets/stats/stat-heading.png"
              alt="Each Jellow Octopus Is Happy And Makes You Smile =)"
              className="mx-auto -rotate-3 transform px-4"
              objectFit="contain"
              height={130}
              placeholder="none"
              layout="constrained"
            />
          </div>
        </BackgroundImage>
        <div className="w-full bg-[#F0F2F5] px-4">
          <div className="specs-text relative mx-auto max-w-6xl text-center font-poppins text-xs font-bold leading-loose lg:text-base lg:leading-9">
            <p className="mb-6">
              With 2 stubby legs and a big round belly, the Jellow Octopus is
              definitely one-of-a-kind. Each Jellowo is uniquely designed and
              programmatically generated from over 99 possible traits including
              their expressions, body, and costumes that they wear! While they
              have a mysterious demeanor, their hearts are made of soft jiggly
              jello that spreads happiness everywhere they go.
            </p>
            <p>
              The Jellowos are stored as ERC-1155 tokens on the Ethereum
              blockchain and hosted on IPFS. The Cost of Jellow Ocotpus NFT is
              yet to be determined.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Specs
